import React from "react";
import styled from 'styled-components';
import Banner1 from '../component/Banner1';
import ca1 from '../assets/product/shoes-01.jpg';
import ca2 from '../assets/product/shoes-02.jpg';
import ca3 from '../assets/product/shoes-03.jpg';
import ca4 from '../assets/product/shoes-04.jpg';
import ca5 from '../assets/product/shoes-05.jpg';
import ca6 from '../assets/product/shoes-06.jpg';
import nv3 from '../assets/n-v-3.mp4';
import Container1 from "../style/container1";
import Mar from '../component/Ticker';


const Case1 = [
  ca1, ca2, ca3, ca4, ca5, ca6
]

const Pass1 = [
  ca1
]

const Headline = styled.div`
   @media only screen and (min-width : 280px) {
width: 100%;
    }
    @media only screen and (min-width : 768px) {
  width: 100%;
    }
    @media only screen and (min-width : 1000px) {
  width: 90%;
    }
    @media only screen and (min-width : 1600px) {
  width: 70%;
    }
`



function Home() {
  return (
    <Container1>
      <video
        // src={'https://dms.licdn.com/playlist/C5605AQH_Xnd2_PwOmw/mp4-720p-30fp-crf28/0/1637333276229?e=1660798800&v=beta&t=k8dMRAjDoE-QSqRQDWqQu-QQ32ofYNAsTNFdtc1Jqdw'}
        src={nv3}
        muted
        autoPlay={"autoplay"}
        preLoad="auto"
        loop />
      <Headline>
      <h3>
        Finally, a great 3D sneakers.
        Say goodbye to tradisional Sneakers,
        single materials.<br /><br /><br />
      </h3>
      </Headline>
      <Banner1 title="P01" body="Starting at $450 usd +" banimg="https://dr.savee-cdn.com/things/6/2/73383bebacf94c7bf60322.jpg" link="/P01"/>
      <Mar />
      <Banner1 title="P02" body="Starting at $1050 usd +" banimg="https://dr.savee-cdn.com/things/6/2/27f83e75c58bb8b3c09cf3.webp" link="/P02" />

    </Container1>
  );
}

export default Home;
