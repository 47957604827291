import React from "react";
import styled from 'styled-components';
import Product from '../component/Product';
import ca1 from '../assets/product/shoes-01.jpg';
import ca2 from '../assets/product/shoes-02.jpg';
import ca3 from '../assets/product/shoes-03.jpg';
import ca4 from '../assets/product/shoes-04.jpg';
import ca5 from '../assets/product/shoes-05.jpg';
import ca6 from '../assets/product/shoes-06.jpg';

const Case1 = [
  ca1, ca2, ca3, ca4, ca5, ca6
]

const Pass1 = [
  ca1
]

const ProductList = styled.div`

`
function P02() {
  return (
    <div>
      <ProductList>
        <Product images={Case1} objfit="cover" />
      </ProductList>
    </div >
  );
}

export default P02;
