import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container0 from '../style/container0';

const MenuItem = styled(Link)`
    margin: 16px;
    color: grey;
    font-weight: 600;
    &:hover {
       background-color: grey;
    }
`
const Bar = styled.nav`
    letter-spacing: 1px;
    display: flex; 
    justify-content: space-between;
    color: black;
    flex-wrap: wrap;
    margin: auto;
`
const Menu = styled.div`
    // border-bottom: ${props => props.borderb};
    display: flex;
             @media only screen and (min-width : 280px) {
    width: 100%;
    flex-direction: column;
    }
    @media only screen and (min-width : 768px) {
    width: 100%;
    flex-direction: row;
    }
    @media only screen and (min-width : 1000px) {
    width: 100%;      
    flex-direction: row;
    }
    @media only screen and (min-width : 1600px) {
    max-width: 1600px;
    flex-direction: row;
    }
`

const Footer = (props) => {
  return (
    <Container0>
      <Bar>
        <MenuItem> </MenuItem>
        <Menu>
          <MenuItem to="/terms">terms</MenuItem>
          <MenuItem to="/privacy">Privacy</MenuItem>
          <MenuItem to="/faq">FAQ</MenuItem>
          <MenuItem to="/">Instagram</MenuItem>
          <MenuItem to="/">Linkedin</MenuItem>
        </Menu>
        </Bar>
    </Container0>
  )
}

export default Footer;
