import React from 'react';
import styled from 'styled-components';
import Marquee from "react-fast-marquee";
import New from "../assets/new.gif";

const Lp = styled.p`
    width: 50%;
    padding: 16px;
`

const BanBody = styled.p`
    width: 30%;
    padding: 16px;
    color: lightgrey;
`
const NewImg = styled.img`
    width: 111px;
    height: 40px;
`

const Ticker = () => (
    <Marquee speed='30' gradient={false} pauseOnHover={true} direction={'left'} >
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        <NewImg src={New} />
        
    </Marquee>
);

export default Ticker;
