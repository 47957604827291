import styled from 'styled-components';

const Container1 = styled.div`
   margin: auto;
   border-bottom: ${props => props.borderb};
   overflow: hidden;

    @media only screen and (min-width : 280px) {
       width: 98%;
       flex-direction: column;
    }
    @media only screen and (min-width : 768px) {
       width: calc(100% - 32px);
       flex-direction: column;
    }
       @media only screen and (min-width : 1000px) {
     width: calc(100% - 32px);
       flex-direction: column;
    }
    @media only screen and (min-width : 1600px) {
     max-width: 1568px;
       flex-direction: column;
    }
`
export default Container1;

