import React from 'react';
import ProductSlider from './ProductSlider.js';
import styled from 'styled-components';
import Container1 from '../style/container1.js';

const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  gap: 16px;
    @media only screen and (min-width : 280px) {
    flex-direction: column;
    }
    @media only screen and (min-width : 768px) {
    flex-direction: column;
    }
    @media only screen and (min-width : 1000px) {
    flex-direction: row;
    }
    @media only screen and (min-width : 1600px) {
    flex-direction: row;
    }
`

const ProductInformation = styled.div`
    display: flex; 
    justify-content: start;
    color: black;
    flex-wrap: wrap;
    flex-direction: column;
    @media only screen and (min-width : 280px) {
     width: 100%;
    text-align: left;
    order: 1;
    }
    @media only screen and (min-width : 768px) {
   width: 100%;
   text-align: left;
   order: 1;
    }
    @media only screen and (min-width : 1000px) {
      width: 50%;
      text-align: right;
      order: 2;
    }
    @media only screen and (min-width : 1600px) {
      width: 50%;
      text-align: right;
      order: 2;
    }
`

const ProductTitle = styled.h1`
   border-top: 1px solid black;
   padding: 8px 0 32px 0;
`
const Lists = styled.ul`
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: start;
   border-top: 1px solid #000;
   padding: 0;
   list-style: none;
   margin-bottom: 32px;
`
const List = styled.li`
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: space-between;
   border-bottom: 1px solid #d5d5d5;
   padding: 0;
   list-style: none;
`
const Caption = styled.p`
   font-size: 0.5rem;
   margin: 16px 0;
   text-transform: uppercase;
   color: grey;
   `

const ProductInfo = styled.div`
    
    display: flex; 
    justify-content: center;
    color: black;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
`

const Aa = styled.a`
background-color: lightgrey;
color: black;
display: flex;
flex-direction: row;
justify-content: space-around;
width: auto;
margin: 8px 0;
border-radius: 4px;
&:hover {
  background-color: black;
  color: white;
}
@media only screen and (min-width : 280px) {
padding: 16px;
    }
    @media only screen and (min-width : 768px) {
padding: 16px;
    }
    @media only screen and (min-width : 992px) {
padding: 12px;
    }
    @media only screen and (min-width : 1200px) {
padding: 12px;
    }
`
const Aabtn = styled.div`
   width: auto;
`
const Product = (props) => {
  return (
    <Container1
    // borderb="1px solid #d5d5d5"
    >
      <ProductContainer >

        <ProductSlider images={props.images} objfit={props.objfit} />

        <ProductInformation>
          <ProductInfo>
            <ProductTitle>
              Advanced Mesh Typology Shoes
            </ProductTitle>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>Design with Grasshopper</p>
            <br />
            <br />
          </ProductInfo>
          <Lists>
            <List><p>LICENSE TYPE</p><p>PERSONAL</p></List>
            <List><p>MODEL SPEC</p><p>.obj (0.5MB)</p></List>
            <List><p>Polygons</p><p>113,520</p></List>
            <Aa href={props.href1}>
              <Aabtn>BUY</Aabtn>
              <Aabtn>$450</Aabtn>
            </Aa>
          </Lists>
          <Lists>
            <List><p>LICENSE TYPE</p><p>BUSINESS</p></List>
            <List><p>MODEL SPEC</p><p>.obj (0.5MB)</p></List>
            <List><p>Polygons</p><p>113,520</p></List>
            <Aa href={props.href2}>BUY      $1050</Aa>
          </Lists>
        </ProductInformation>

      </ProductContainer>
      <Caption>
        COMMENT LICENSE:Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel congue elit, facilisis ornare massa. Aenean sapien velit, fermentum quis neque sit amet, pretium malesuada eros. Nunc non placerat felis, ac sagittis massa. Integer at laoreet nunc. Etiam gravida, urna dapibus interdum sodales, orci nibh aliquam orci, non rutrum orci mauris et dolor. Quisque consequat odio diam, quis imperdiet est tincidunt a. Donec malesuada lobortis ligula in condimentum. Nam vel feugiat turpis. Maecenas ex ligula, malesuada eget varius vel, consectetur in sem. Maecenas ac nunc tempus, pharetra justo quis, venenatis risus. Aenean ornare sem felis. Nam sit amet ante sit amet sapien laoreet ullamcorper nec non nibh.
        Sed vitae felis in erat tristique ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tortor risus, ullamcorper quis leo vitae, pretium sagittis dolor. Fusce posuere, nulla a euismod pharetra, mauris velit euismod neque, suscipit convallis nibh erat nec lectus. Nullam consequat rhoncus lobortis. Aliquam diam tellus, volutpat sit amet est aliquet, mollis rhoncus est. Aliquam finibus rhoncus rhoncus. In convallis posuere tempus. Vestibulum ut blandit metus. Duis sit amet mattis diam. Proin iaculis, nunc vitae semper molestie, enim nibh euismod elit, quis ultrices lorem ipsum at lacus. Maecenas ac nunc rhoncus ipsum posuere ultricies sit amet quis ipsum. Proin posuere pulvinar justo, ut porta lacus vulputate nec. Ut maximus elit maximus, varius massa at, ultrices tellus. Cras sed tristique enim.
      </Caption>
    </Container1 >
  )
}

export default Product;
