import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Lp = styled.p`
    width: 50%;
    padding: 16px;
`

const BanBody = styled.p`
    width: 30%;
    padding: 16px;
    color: lightgrey;
`

const BanTitle = styled.h3`
    width: 100%;
    padding: 16px;
    color: lightgrey;
    text-align: left;
`
const Banner = styled(Link)`
    width: 100%;
    background-color:  black;
    border-radius: 12px;
    display: flex; 
    margin: 32px 0;
     @media only screen and (min-width : 280px) {
flex-direction: column;
justify-content: space-between;
height: 600px;
    }
    @media only screen and (min-width : 768px) {
flex-direction: column;
height: auto;
    }
    @media only screen and (min-width : 1000px) {
flex-direction: row;
height: auto;
    }
    @media only screen and (min-width : 1600px) {
flex-direction: row;
height: auto;
    }
  `

const BanImg = styled.img`
    border-radius: 2px;
    display: flex; 
    border: none;
    // background-size: cover;
    // background-image: ${props => props.banimg};
  @media only screen and (min-width : 280px) {
    width: calc(100% - 24px);
    height: auto;
        margin: 12px;
    }
    @media only screen and (min-width : 768px) {
    width: 50%;
    height: auto;
        margin: 24px;
    }
    @media only screen and (min-width : 1000px) {
    width: 50%;
    height: auto;
        margin: 24px;
    }
    @media only screen and (min-width : 1600px) {
    width: 50%;
    height: auto;
        margin: 24px;
    }
    `

const BanInfo = styled.div`
    background-color:  black;
    border-radius: 16px;
    display: flex; 
    flex-direction: column;
    @media only screen and (min-width : 280px) {
    width: auto;
    height: auto;
    }
    @media only screen and (min-width : 768px) {
width: 50%;
    height: auto;
    }
    @media only screen and (min-width : 1000px) {
width: 50%;
    height: auto;
    }
    @media only screen and (min-width : 1600px) {
width: 50%;
    height: auto;
    }
  `

const Banner1 = (props) => {
  

  return (
    <Banner to={props.link}>
        <BanInfo>
        <BanTitle>{props.title}</BanTitle>
        <BanBody>{props.body}</BanBody>
       </BanInfo>
      <BanImg src={props.banimg} />
      </Banner>
  )
}

export default Banner1;
