import React from "react";
import styled from 'styled-components';
import Product from '../component/Product';
import ca1 from '../assets/product/shoes-01.jpg';
import ca2 from '../assets/product/shoes-02.jpg';
import ca3 from '../assets/product/shoes-03.jpg';
import ca4 from '../assets/product/shoes-04.jpg';
import ca5 from '../assets/product/shoes-05.jpg';
import ca6 from '../assets/product/shoes-06.jpg';
import SNK1 from '../assets/1.jpg';
import SNK2 from '../assets/2.jpg';
import SNK3 from '../assets/3.jpg';
import SNK4 from '../assets/4.jpg';
import SNK5 from '../assets/5.jpg';
import SNK6 from '../assets/6.jpg';

const Case1 = [
  SNK1, SNK2, SNK3, SNK4, SNK5, SNK6
]

const Pass1 = [
  ca1, ca2, ca3, ca4, ca5, ca6
]

const ProductList = styled.div`

`
function P01() {
  return (
    <div>
      <ProductList>
        <Product images={Case1} objfit="cover" href1="https://buy.stripe.com/28ocNm4QU6n96Z26or" href2="https://buy.stripe.com/5kAaFe4QU12P1EI001" />
      </ProductList>
    </div >
  );
}

export default P01;
