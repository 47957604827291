import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import styled from "styled-components"

const Keen = styled.div`
  width:100%;
  border-radius: 12px;
  @media only screen and (min-width : 280px) {
     order: 1;
     margin: auto;
    }
    @media only screen and (min-width : 768px) {
     order: 1;
     margin: auto;
    }
    @media only screen and (min-width : 1000px) {
     order: 2;
     margin: auto;
    }
    @media only screen and (min-width : 1600px) {
     order: 2;
     margin: auto;
    }
`

const ProductSlider = (props) => {
  const [sliderRef] = useKeenSlider({ loop: false })
  const hasImages = Array.isArray(props.images)

  return (
    <Keen ref={sliderRef} className="keen-slider">
      {hasImages && props.images.map((imageUrl, index) =>
        <div className="keen-slider__slide" key={index} original={imageUrl}>
          <img src={imageUrl} alt={imageUrl} objfit={props.objfit} />
        </div>)}
    </Keen>
  )
}

export default ProductSlider;
