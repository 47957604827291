import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container0 from '../style/container0';

const MenuItem = styled(Link)`
    margin: 16px;
    color: black;
    font-weight: 600;
    &:hover {
       background-color: black;
    }
`
const Bar = styled.nav`
    letter-spacing: 1px;
    display: flex; 
    justify-content: space-between;
    color: black;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
         @media only screen and (min-width : 280px) {
width: 100%;
    }
    @media only screen and (min-width : 768px) {
width: 100%;
    }
    @media only screen and (min-width : 1000px) {
width: 100%;
    }
    @media only screen and (min-width : 1600px) {
max-width: 1600px;
    }
`
const Menu = styled.div`
    // border-bottom: ${props => props.borderb};
    display: flex;
`

const Nav = (props) => {
  return (
    <Container0>
      <Bar>
        <MenuItem to="/">Parametriks</MenuItem>
        <Menu>
          <MenuItem to="/P01">P01</MenuItem>
          <MenuItem to="/P02">P02</MenuItem>
        </Menu>
      </Bar>
    </Container0>
  )
}

export default Nav;
