import styled from 'styled-components';

const Container0 = styled.div`
    width: 100%;
    overflow: hidden;
    background-color: ${props => props.bgcolor};
    border-bottom: ${props => props.borderb};
`
export default Container0;

