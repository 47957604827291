import React from "react";
import styled from 'styled-components';


const ProductList = styled.div`

`
function Privacy() {
  return (
    <div>
    <ProductList>
      <p>
          2.2 Please note that the information you provide may be necessary for us to provide the Services and comply with our legal obligations. Without such information, we may not be able to process your order or to answer your queries.

          2.3 Internet and network activity and technical information may be collected using our own or third party cookies and similar tracking technology, as explained further in the “Advertising” section below and our Cookie Notice, which is available here. Collecting this information enables us to better understand how visitors use the Services, where they come from, and what content is of interest. We use this information for our own operational, commercial, and quality assurance purposes, including internal analytics and to improve the quality and relevance of our Services.

          3. Who does SSENSE share your Personal Data with?

          3.1 We may disclose your Personal Data to the following categories of recipients:

          (a) to our group companies for purposes consistent with this Policy, and in particular, so that they may contact you regarding products and services that may be of interest to you. Our group companies include: Atallah Group Inc., Atallah Group Limited, Atallah Hong Kong Limited, Atallah Group US Inc., GAI Services PTY Limited, Atallah International Inc.

          (b) to our third party vendors and services providers who provide data processing services to us, or who otherwise process Personal Data for purposes that are described in this Policy or notified to you when we collect your Personal Data. This may include disclosures to service providers we use in connection with the services they provide to us, including to support us in areas such as IT platform management or support services, infrastructure and application services, email and digital marketing, and data analytics.

          (c) to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person.

          (d) to our professional advisors, such as auditors, advisors, legal representatives and similar providers in connection with the professional services that they provide to us.

          (e) to a potential buyer (and its agents and advisers) in connection with any proposed purchase, merger or acquisition, assignment, transfer, or other disposition of any part of our business or assets (including in connection with any bankruptcy or similar proceedings).

          4. Advertising

          We may use third-party advertising companies and networks to display advertisements that may be of interest to you, based on how you browse and shop online, when you access and use the Services and to serve you with SSENSE ads across the Internet and through other channels, such as social media platforms. Some of these advertising networks may be members of the Network Advertising Initiative (“NAI”) or participate in the Digital Advertising Alliance's ("DAA") Self-Regulatory Program. To learn more about your choices relating to members of the NAI visit their website at https://optout.networkadvertising.org/. To learn more about your choices relating to networks that participate in the DAA Program, please visit http://www.aboutads.infochoices. Please note that options you select are browser and device-specific. See the policies of each social media platform for additional information about these types of ads, including how to manage your display settings for these ads.

          Please be aware that even if you choose to restrict, opt-out or modify your preferences, you may still see or receive SSENSE advertisements but such advertisements will not be based upon your browsing history.

          5. Do Not Track

          We do not monitor or follow “Do Not Track” signals because there is no standard interpretation or practice for such signals. Therefore, we handle all user information consistent with this Policy.

          6. Data security, retention and deletion

          6.1 We use appropriate technical and organizational measures to protect your Personal Data. The measures we use are designed to provide a level of security appropriate to the sensitivity of your Personal Data, including physical measures, such as secure areas, technical measures and organizational measures such as employee security through vetting and supervision.

          6.2 We retain your Personal Data where we have an ongoing legitimate business need to do so, for example, to provide you with a product you have requested and honor return and chargeback periods, or to comply with applicable legal, tax or accounting requirements.

          7. Profiling

          In some instances, we may use your Personal Data in order to better understand your preferences and to provide customized products or services to you. However, we do not make any decisions based solely on automated processing of such data, which either produce legal effects that concern you or similarly significantly affect you.

          8. Minors

          The Services are directed to individuals who are permitted to share their Personal Data without parental consent. If you are under age 16, please do not use or register on the Services. If you are the parent of a minor under age 16, and believe your child has provided us with Personal Data, please contact us as directed below in the “Contact us” section.

          9. Your Privacy Rights

          9.1 All U.S. users of the Services:

          (a) You may update some of your Personal Data, such as if your address changes, in your account settings on the Website or in the App.

          (b) You have the right to opt-out of marketing communications we send you. Specifically, (i) You may unsubscribe from our newsletters or marketing emails at any time by selecting the unsubscribe option in the “Email Preferences” section of your account, or by clicking the unsubscribe link at the bottom of any email. We may still send you important information about the Services, your account, or your transactions. (ii) To opt-out of other forms of marketing (such as postal marketing), please contact us using the contact details provided below.

          9.2 California residents under the California Consumer Privacy Act (CCPA):

          (a) California residents may request the following information from us, for the 12 month period preceding your request:

          The categories of Personal Data that we collect, use, disclose, and sell (if applicable);
          The categories of sources from which Personal Data was collected;
          Our business or commercial purpose for collecting or selling (if applicable) Personal Data;
          The categories of third parties with whom we share Personal Data; and
          The specific pieces of Personal Data that we have collected about you.
          (b) California residents may request that we delete your Personal Data (and request our service providers do the same). Please note that deletion requests are subject to certain limitations, for example, we may retain Personal Data as permitted by law, such as for tax or other record keeping purposes, to maintain an active account, and to process transactions and facilitate customer requests.

          (c) California residents may submit a request by emailing us at dataprotection@ssense.com. In your email, please describe your request, and provide your name, telephone number, and the email address associated with your SSENSE account. You can also contact us by phone at +1 877 637 6002.

          You have the right not to receive discriminatory treatment from us in service or price if you exercise any of the privacy rights conferred by the CCPA.

          California residents may authorize another person (your “agent”) to submit a request on your behalf. Please note that we are required to verify that your agent has been properly authorized to request information on your behalf and this may take additional time to fulfill your request.

          We will aim to complete all CCPA requests as soon as reasonably practicable and consistent with applicable law.

          (d) California Shine the Light. If you are a California resident, you may have the right to request that businesses provide you with information about the entities with which they have shared certain categories of customers' personal information for the entities own direct marketing purposes during the preceding calendar year. SSENSE has not shared such information for third parties’ direct marketing purposes.  If you have additional questions, please email us at dataprotection@ssense.com.

          10. Linking to other websites

          The Website or App may contain hyperlinks to sites owned or operated by third parties. These links are provided for your convenience only. Your use of third party sites is subject to the terms and policies applicable to those sites.

          11. Updates to this Policy

          We may update this Policy from time to time.  You can see when this Policy was last updated by checking the “last updated” date displayed at the top of this Policy. To the extent permitted by law, your continued use of the Services constitutes your consent to the Policy. the top of this Policy.

          12. Contact us

          If you have any questions this Policy, please contact us by phone +1 877 637 6002 or via email at dataprotection@ssense.com. You can also write to us at:

          SSENSE
          Data Protection
          333 Chabanel Street W #900
          Montreal QC H2N 2G1
          Canada



          Recruiting Privacy Policy

          Applicant confirms and agrees that the information provided by Applicant is accurate and complete, and that SSENSE may use such information in evaluating Applicant’s application in accordance with the Privacy Policy and Terms and Conditions set forth on SSENSE’s website subject to the following additional considerations:

          We collect Personal Data directly from you during the application and hiring process (e.g., application forms that you are asked to complete, or in interviews or through the completion of tests or surveys).  We may also obtain Personal Data about you from third parties in connection with your application, such as background checks you consent to, former employers or other references you provide.
          We use the Personal Data we obtain to process job applications and select candidates, which includes reviewing candidates’ submissions, scheduling and taking interviews, preparing internal evaluations, preparing salary recommendations for candidates, entering into an employment agreement and integrating the employee into organizational structures, and meeting various employment and employee onboarding needs (e.g., processing the hire in our HR systems, setting payroll and benefits, etc.).
          Upon employment, any of your Personal Data that we retain in connection with your application and onboarding process will subsequently be subject to internal company policies on employee information instead of this notice or the Privacy Policy on our website.
          SSENSE is an Equal Employment Opportunity employer and we are committed to giving equal employment and promotional opportunities to qualified individuals, regardless of race, color, creed, ancestry, age, sex, religion, national origin, disability, veteran status, gender identity or expression, sexual orientation, genetic information, and any other category protected under federal, state or local law.  It is also SSENSE’s policy to comply with all applicable federal, state, and local laws respecting consideration of unemployment status in making hiring decisions.  SSENSE will make a reasonable accommodation to disabled individuals to assist in the application and hiring process and to qualified individuals with disabilities in the performance of essential job functions, in accordance with federal, state and local law.  SSENSE is subject to the Workers' Compensation laws in the states where it does business.  Please note that if you are located in a jurisdiction (state or local) that prohibits employers from requiring applicants to disclose specific types of information, you are not required to disclose such information.
          </p>
          </ProductList>
          </div>
  );
}

export default Privacy;
