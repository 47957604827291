import React from 'react';
import Nav from './component/Nav';
import Footer from './component/Footer'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import P01 from './pages/P01';
import P02 from './pages/P02';
import './style/app.css';
import ReactGA from 'react-ga';
import RouteChangeTracker from './component/RouteChangeTracker'

const TRACKING_ID = "G-QEVVK513HW";
ReactGA.initialize(TRACKING_ID);



function App() {
  return (
    <Router>
    <div className="app">
        <Nav />
        <Route path="/" exact component={Home} />
        <Route path="/P01" component={P01} />
        <Route path="/P02" component={P02} />
        <Route path="/Faq" component={Faq}/>
        <Route path="/Terms" component={Terms} />
        <Route path="/Privacy" component={Privacy} />

        <Footer />
        
    </div>
      <RouteChangeTracker />
    </Router>
  );
}

export default App;
